<template>
  <v-card rounded="lg">
    <v-card-title
      :class="`${color} white--text text-h6`"
    >
      <v-icon dark class="mr-3">{{ icon }}</v-icon>
      {{ title }}
    </v-card-title>
    <v-card-text style="max-height: 400px; overflow-y: auto">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        class="elevation-0"
        hide-default-footer
      />
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    props: {
      color: String,
      icon: String,
      title: String,
      headers: Array,
      items: Array
    }
  }
</script>

<style scoped>
  .primary-color {
    background: linear-gradient(90deg, #EC6A23,#E64A19);
  }
  .success-color {
    background: linear-gradient(90deg, #54EB47, #33a728);
  }
  .accent-color {
    background: linear-gradient(90deg, #0F5BAA, #0e4a8b);
  }  
  .error-color {
    background: linear-gradient(90deg, #EB4747, #bd3636);
  }
</style>